$(document).ready(function() {
    $('.popup-trigger').each(function() {
        var element = $(this);
        var timeout = parseInt(element.attr('data-timeout'));
        var frequency = parseInt(element.attr('data-frequency'));
        var id = element.attr('id');
        var cookie = Cookies.get(id);

        // Do not show popup if any type of cookie exists
        if(cookie == undefined) {
            setTimeout(function() {

                // check what type of popup it is
                if (element.hasClass('popup-overlay')) {
                    element.fadeIn();
                    element.find('.popup-window').addClass('active');
                } else {
                    element.addClass('active');
                }

                // set cookie based on admin input on how often it shoud appear
                Cookies.set(id, 'open', { expires: frequency });
            }, timeout);
        }
    });

    // rules for country select popup are defined in general.js

    $("body").on("click", "[data-close-popup]", function(){
        var element = $(this).parents('.popup-trigger');
        var id = element.attr('id');
        var closedTime = parseInt(element.attr('data-closed'));
        var cookie = Cookies.get(id);


        Cookies.set(id, 'closed', { expires: closedTime });

        if (element.hasClass('popup-overlay')) {
            element.find('.popup-window').removeClass('active');
            element.fadeOut();
        } else {
            element.removeClass('active');
        }
    });

    $('.popup-window .btn-cta').click(function() {
        var element = $(this).parents('.popup-trigger');
        var id = element.attr('id');
        var closedTime = parseInt(element.attr('data-closed'));

        Cookies.set(id, 'closed', { expires: closedTime });
        element.removeClass('active');
    });
});